import './HeaderPublic.css';
import React from "react";
import Nav from 'react-bootstrap/Nav';

function HeaderPublic() {
  return (
      <div className="app-nav">
        <Nav
          activeKey="/"
          className="justify-content-end"
        >
          <Nav.Item>
            <Nav.Link href="https://dev.freepetz.com">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/">About</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/register">Register</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/login">Log In</Nav.Link>
          </Nav.Item>
        </Nav>
        Free Petz
      </div>
  );
}

export default HeaderPublic;