import './Account.css';
import React from "react";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="account-main">
        Account
        <br />
      </div>
    );
  }
}

export default Account;