import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from "react-cookie";
import Header from './Header.js';

const withHeader = (BaseComponent) => {
    class HeaderDecorated extends React.Component {
        render() {
            return (
                <div>
                    <Header /> 
                    <BaseComponent {...this.props} />
                </div>
            );
        }
    }

    return withRouter(withCookies(HeaderDecorated));
}

export default withHeader;