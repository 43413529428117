import ApiClient from '../api';

const login = (email, password) => {
    const inputObject = {
        'email': email,
        'password': password
    };
    const input = JSON.stringify(inputObject);
    const urlPath = "/api/Account/LoginAccount";
    var response = ApiClient.post(urlPath, input);
    return response;
};

const createAccount = (email, password, firstName, lastName, accountName) => {
    const inputObject = {
      'email': email,
      'password': password,
      'firstName': firstName,
      'lastName': lastName,
      'accountName': accountName
    };
    const input = JSON.stringify(inputObject);
    const urlPath = "/api/Account/CreateAccount";
    var response = ApiClient.post(urlPath, input);
    return response;
};

const forgotPassword = (email) => {
    const inputObject = {
      'email': email
    };
    const input = JSON.stringify(inputObject);
    const urlPath = "/api/Account/ForgotPassword";
    var response = ApiClient.post(urlPath, input);
    return response;
};

const resetPassword = (passwordResetToken, password) => {
    const inputObject = {
      'passwordResetToken': passwordResetToken,
      'password': password
    };
    const input = JSON.stringify(inputObject);
    const urlPath = "/api/Account/ResetPassword";
    var response = ApiClient.post(urlPath, input);
    return response;
};

const changePassword = (passwordResetToken, password) => {
    const inputObject = {
      'passwordResetToken': passwordResetToken,
      'password': password
    };
    const input = JSON.stringify(inputObject);
    const urlPath = "/api/Account/ChangePassword";
    var response = ApiClient.post(urlPath, input);
    return response;
};

const AccountApi = { login, createAccount, forgotPassword, resetPassword, changePassword };
export default AccountApi;