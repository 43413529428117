import './Login.css';
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Col} from 'react-bootstrap';
import AccountApi from '../../services/account';
import { withCookies } from "react-cookie";

const buttonStyle = { marginRight: "10px" };

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      cookies: props.cookies
    }
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    AccountApi.login(this.state.username, this.state.password).then(data => {
      this.setState({password: ""});
      var accountId = data.accountId;
      console.log(accountId);
      this.props.cookies.set("react-auth", accountId, { path: "/" });
      this.props.history.push('/account');
    });
  };

  handleUsernameChange(event) {
    localStorage.setItem("username", event.target.value);
    this.setState({username: event.target.value});
  }

  handlePasswordChange(event) {
    localStorage.setItem("password", event.target.value);
    this.setState({password: event.target.value});
  }

  render() {
    return (
      <div className="login-main">
        Login
        <Form noValidate onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Control
                type="text"
                name="username"
                placeholder="Username"
                value={this.state.username}
                onChange={this.handleUsernameChange}
                //isInvalid={touched.zip && errors.zip}
              />
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                //isInvalid={touched.zip && errors.zip}
              />
              <Form.Control.Feedback type="invalid">
                {/*errors.zip*/}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button
            type="submit"
            style={buttonStyle}
          >
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default withCookies(Login);