import './Register.css';
import React from "react";

class Register extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="register-main">
        Register
        
        <br />
      </div>
    );
  }
}

export default Register;