import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from "react-cookie";

/**
 * Higher-order component (HOC) to wrap restricted pages
 */
const restrict = (BaseComponent) => {
    class Restricted extends React.Component {
        componentDidMount() {
            this.checkAuthentication(this.props);
        }
        
        componentDidUpdate(nextProps) {
            if (nextProps.location !== this.props.location) {
                this.checkAuthentication(nextProps);
            }
        }

        checkAuthentication(params) {
            const { history, cookies } = params;
            var authCookie = cookies.get("react-auth");
            if(authCookie) {
                console.log(authCookie);
            }
            else {
                history.replace({ pathname: '/login' });
            }
        }

        render() {
            return <BaseComponent {...this.props} />;
        }
    }

    return withRouter(withCookies(Restricted));
}

export default restrict;