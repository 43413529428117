import './App.css';
import Main from './pages/main/Main';
import Account from './pages/account/Account';
import Pets from './pages/pets/Pets';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import { Switch, Route } from 'react-router-dom';
import restrict from './components/restricted/Restricted.js';
import withHeader from './components/header/HeaderDecorated.js';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route path='/account' component={restrict(withHeader(Account))}/>
        <Route path='/pets' component={restrict(withHeader(Pets))}/>
        <Route path='/login' component={withHeader(Login)}/>
        <Route path='/register' component={withHeader(Register)}/>
        <Route path='/' component={withHeader(Main)}/>
      </Switch>
    </div>
  );
}

export default App;