const baseUrl = "https://dev-api.freepetz.com";

const get = (urlPath) => {
    return sendRequest(urlPath, "GET");
};

const post = (urlPath, input) => {
    return sendRequestBody(urlPath, input, "POST");
};

const put = (urlPath, input) => {
    return sendRequestBody(urlPath, input, "PUT");
};

const senddelete = (urlPath) => {
    return sendRequest(urlPath, "DELETE");
};

const sendRequest = (urlPath, verb) => {
    const url = baseUrl + urlPath;
    return fetch(url, { method: verb, mode: "cors", credentials: "include", headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const sendRequestBody = (urlPath, input, verb) => {
    const url = baseUrl + urlPath;
    return fetch(url, { method: verb, mode: "cors", credentials: "include", body: input, headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const upload = (urlPath, input) => {
    const url = baseUrl + urlPath;
    return fetch(url, { method: "POST", mode: "cors", credentials: "include", body: input, headers: { 'Content-Type': "multipart/form-data" }})
    .then(response => response.json());
};

const ApiClient = { get, post, put, senddelete, upload, sendRequest, sendRequestBody };
export default ApiClient;