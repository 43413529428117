import './Main.css';
import React from "react";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="app-main">
          Welcome to the Free Petz Listing Site!
          <br />
      </div>
    );
  }
}

export default Main;