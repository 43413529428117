import './Header.css';
import React from "react";
import HeaderPublic from '../header-public/HeaderPublic';
import HeaderAuth from '../header-auth/HeaderAuth';
import { withRouter } from 'react-router-dom';
import { withCookies } from "react-cookie";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: this.checkAuthentication(props)
    }

    this.onLogout = this.onLogout.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  checkAuthentication(props) {
      const { cookies } = props;
      var authCookie = cookies.get("react-auth");
      if(authCookie) {
          return true;
      }
      return false;
  }

  onLogout() {
    this.setState({isLoggedIn: false});
    const { history, cookies } = this.props;
      cookies.remove("react-auth");
      history.replace({ pathname: '/login' });
  }

  onLogin() {
    this.setState({isLoggedIn: true});
  }

  render() {
    return (
        <header className="app-header">
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
            integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
            crossorigin="anonymous"
          />
          {this.state.isLoggedIn
            ? <HeaderAuth onLogout={this.onLogout} />
            : <HeaderPublic />
          }
        </header>
    );
  }
}

export default withRouter(withCookies(Header));