import './Pets.css';
import React from "react";
import Nav from 'react-bootstrap/Nav';

class Pets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="pets-main">
        <Nav
          activeKey="list"
          variant="tabs"
          onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
          <Nav.Item>
            <Nav.Link eventKey="list">View Pets</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="post">Post a Pet</Nav.Link>
          </Nav.Item>
        </Nav>
        Pets
        <br />
      </div>
    );
  }
}

export default Pets;