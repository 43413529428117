import './HeaderAuth.css';
import React from "react";
import Nav from 'react-bootstrap/Nav';

function HeaderAuth(props) {
  return (
      <div className="app-nav">
        <Nav
          activeKey="/"
          className="justify-content-end"
          onSelect={(selectedKey) => { if(selectedKey === 'logout') { props.onLogout(); }}}
        >
          <Nav.Item>
            <Nav.Link href="https://dev.freepetz.com">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/">About</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/pets">Pets</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/account">Account</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="logout">Logout</Nav.Link>
          </Nav.Item>
        </Nav>
        Free Petz
      </div>
  );
}

export default HeaderAuth;